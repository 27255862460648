<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <DiscountTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-row form class="form-group">
                        <v-col sm="3">Заголовок</v-col>
                        <v-col sm="9">{{ entity.title }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Картинка</v-col>
                        <v-col sm="9"><v-img width="300px" v-if="entity.picture" :src="imageSrc(entity.picture)" /></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Сайт</v-col>
                        <v-col sm="9">{{ entity.site ? entity.site.title : '' }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Тип</v-col>
                        <v-col sm="9">{{ DISCOUNT_TYPE_TITLE[entity.type] }}</v-col>
                    </v-row>

                    <template v-if="entity.type === DISCOUNT_TYPE.PERCENT">
                        <v-row form class="form-group">
                            <v-col sm="3">Размер скидки</v-col>
                            <v-col sm="9">{{ entity.percent }}%</v-col>
                        </v-row>
                        <v-row form class="form-group">
                            <v-col sm="3">Скидка действует</v-col>
                            <v-col sm="9">
                                {{ entity.all_menus ? 'На всё меню' : 'На выбранные позиции:' }}
                                <div v-if="!entity.all_menus">
                                    <div v-for="item in entity.products" :key="`products-${item.id}`">
                                        {{ item.title }}
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </template>

                    <v-row form class="form-group">
                        <v-col sm="3">Акция применяется</v-col>
                        <v-col sm="9">{{ delivery_type }}</v-col>
                    </v-row>

                    <v-row form class="form-group">
                        <v-col sm="3">Время проведения</v-col>
                        <v-col sm="9">
                            <table>
                                <tr v-for="item in filteredSchedules" :key="`schedules-${item.id}`">
                                    <td>{{ scheduleShortFormated(item.open_weekday, item.open_hours, item.open_minutes) }}</td>
                                    <td>&nbsp;&nbsp;-&nbsp;&nbsp;</td>
                                    <td>{{ scheduleShortFormated(item.close_weekday, item.close_hours, item.close_minutes) }}</td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Позиция</v-col>
                        <v-col sm="9">{{ entity.position }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Активен</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(entity.enabled)}`">
                                &nbsp;{{entity.enabled ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Применять в разделе доставки</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(entity.for_delivery)}`">
                                &nbsp;{{entity.for_delivery ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Применять в электронном меню</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(entity.for_menu)}`">
                                &nbsp;{{entity.for_menu ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >К списку</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import DiscountTabLinks from '../../components/discounts/DiscountTabLinks';

    import { imageSrc, scheduleShortFormated } from '../../helpers';
    import { DISCOUNT_TYPE, DISCOUNT_TYPE_TITLE } from '../../vars';

    export default {
        name: 'DiscountDetail',

        components: {
            DiscountTabLinks,
        },

        data() {
            return {
                DISCOUNT_TYPE, 
                DISCOUNT_TYPE_TITLE,
                imageSrc,
                scheduleShortFormated,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('discounts', {
                entity: state => state.entity
            }),
            isAdmin() {
                return this.user.admin;
            },
            delivery_type() {
                return this.entity && this.entity.delivery_type ? {
                    all: 'На доставку и самовывоз',
                    delivery: 'Только на доставку',
                    pickup: 'Только на самовывоз'
                }[this.entity.delivery_type] : ''
            },
            filteredSchedules() {
                return this.entity.schedules ? this.entity.schedules.filter(item => item.enabled) : [];
            }
        },

        async mounted() {
            const id = this.$route.params.id;
            if(id) {
                await store.dispatch('discounts/get', {id});
            }
        },

        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                this.$router.push({ path: '/discounts' });
            },
        },
    };
</script>